html {
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}
